.square {
    width: 50rem;
    height: 35rem;

    
  }

  .logintxt
  {
    width: 70%;
    height: 3.5rem;
   
  }

  .loginbtn
  {

    height: 3.5rem;
}
.login2 
{

background-color:#27276b  ;
}
.login1
{
    height: 20rem;
    border-color: #27276b   ;
    border-radius: 5px;
}

.typed-cursor 
{
    font-size: 36px;
    color: white;
}
#typed
{
    color: white;

}
