.contenedor
{
    height: 80vh;
}
.logintxtreg
{
    width: 60%;
    height: 3.5rem;
}

.reg1
{
    height: 30rem;
    
    border-radius: 5px;
}
.regbtn
{
    height: 3.5rem;     
    width: 100%;    
}
.title
{
height: 30rem;
}
@media (max-width: 992px) {
    .justify-custom {
      justify-content: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .justify-custom {
      justify-content: end !important;
    }
  }