

@font-face {
  font-family: 'Archivo';
  src: url('../css/fonts/Archivo/Archivo-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Distancia';
  src: url('../css/fonts/Archivo/Archivo-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo-Bold';
  src : url('../css/fonts/Archivo/Archivo-ExtraBold.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}



$navbar-bg: #27276b;
$navbar-light-active-color:white;
$border-width: 1.5px;
$card-title-color: black;
$card-subtitle-color:  black;
$card-cap-color: black;
$navy: #27276b;
$mustard: #c4b070;


$theme-colors: (
  "success": $navy,
  "danger": $mustard
);

$list-group-active-bg: #c4b070;
$accordion-button-active-bg:#27276b;
$accordion-button-active-color:white;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1660px // Aumenta el tamaño máximo del contenedor XXL
);
@import "~bootstrap/scss/bootstrap";

.progress-bar {
 
  background-color:  #c4b070; // Color de fondo de la barra
}
.bg-aboutus-video
{
  background-color: #27276b !important; 
}
.bg-aboutus-brands
{
  background-color: #e6e5e0 !important; 
}
h1{
  font-family: 'Archivo-Bold';
  font-size: 56px;
}
.card-archivo-title
{
  font-family: 'Archivo-Bold';
  font-size: 24px;
  height: 5rem;
}
.card-archivo-price
{
  font-family: 'Archivo';
  font-size: 26px;
}
.card-archivo-price-bold
{
  font-family: 'Archivo-Bold';
  font-size: 26px;
  color: #27276b;
}
.border-none{
border: none;
}

h4
{
  font-family: 'Archivo';
  font-size: 48px;
}

.title-reg{
  font-family: 'Archivo-Bold';
  font-size: 48px;
}
.check
{
  color: #27276b;
}
.icon{
  font-size: 24px;
}
.text-bold-archive
{
  font-family: 'Archivo-Bold';
  font-size: 16px;
}

.text-archive 
{
  font-family: 'Archivo';
  font-size: 16px;
}
.text-archive-aboutus 
{
  font-family: 'Archivo';
  font-size: 32px;
}
.CourseTitle
{
  font-family: 'Archivo-Bold';
  font-size: 32px;
}
.navbar {
    background-color: #27276b !important; // Cambia al color deseado

  }
  
  .container-video {
    /* Máximos anchos del contenedor en diferentes tamaños de pantalla */
    width: 100%;
    max-width: 1660px; /* sm */
    margin-right: auto;
    margin-left: auto;
  
    /* Añade estilos específicos según los breakpoints de Bootstrap */
    @media (min-width: 576px) {
      max-width: 540px; /* sm */
    }
    @media (min-width: 768px) {
      max-width: 960px; /* md */
    }
    @media (min-width: 992px) {
      max-width: 960px; /* lg */
    }
    @media (min-width: 1200px) {
      max-width: 1140px; /* xl */
    }
    @media (min-width: 1400px) {
      max-width: 1660px; /* xxl */
    }
  }
  

  .w-40 {
    width: 30%;
  }
