.text-mustard
{
    color:  #f2f1EB;
}
.bg-navy
{
    background-color: #27276b
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* O el tamaño que necesites */

}

.loading-image {

  width: 15rem !important; /* Tamaño inicial de la imagen */
  animation: pulsate 1.5s infinite ease-in-out;
  margin-bottom: 1rem !important;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



.navbar-expand-lg .navbar-collapse.offcanvas-collapse.open {
  visibility: visible;
  transform: translateX(-100%);
}
