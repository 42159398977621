body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card
{
width: 100%;
transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.card-img-top {
  transition: transform 0.3s ease;
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

.card-title {
  transition: color 0.3s ease;
}

.card:hover .card-title {
  color:  #27276b; /* Cambia al color deseado */
}

.card-body {
  transition: background-color 0.3s ease;
}

.card:hover .card-body {
  background-color: #f8f9fa; /* Cambia al color deseado */
}
#btnmostrar
{
  opacity: 0  ;
}
.card-req
{
width: 50% !important;

}
.discount-badge {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #27276b;
  color: white;
  padding: 5px 50px 5px 20px;
  font-weight: bold;
  font-size: 14px;
  clip-path: polygon(100% 0, 0% 0, 15% 50%, 0% 100%, 100% 100%);
  z-index: 10; /* Asegura que el badge esté por encima de otros elementos */

}

.discount-badge:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: 60px;
  height: 0;
  border-style: solid;
  border-width: 15px 30px 15px 0;
  border-color: transparent #27276b transparent transparent;
  transform: translateY(-50%);
}

.discount-badge:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -60px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 30px;
  border-color: transparent transparent transparent #27276b;
  transform: translateY(-50%);
}

.card:hover .discount-badge {
  z-index: 10; /* Asegura que el badge esté por encima del contenido de la card */
  opacity: 1; /* Mantén la opacidad al 100% para que no desaparezca */
}
