.card-animation {
    opacity: 0;
    width:100%;

  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
      overflow-x: hidden;
    }
  }
  
  .fade-in-right {
    animation: fadeInRight 0.5s ease-out forwards;
  }

  